<template>
  <div class="Privacy">
    <Header/>
    <div class="contents">
      <div class="contentWrap">
        <div class="title">隐私设置</div>
        <div class="list">
          <div class="item">
            <div class="left">
              <div class="name">简历公开</div>
              <div class="desc">正在找工作，企业可以看到我的简历</div>
            </div>
            <el-switch
                style="display: block"
                v-model="value2"
                active-color="#13ce66"
                @change="change1"
                inactive-color="#C9CDD4">
            </el-switch>
          </div>
          <div class="item">
            <div class="left">
              <div class="name">仅投递公司可见</div>
              <div class="desc">仅投递公司可见</div>
            </div>
            <el-switch
                style="display: block"
                v-model="value3"
                @change="change2"
                active-color="#13ce66"
                inactive-color="#C9CDD4">
            </el-switch>
          </div>
          <!--          <div class="item">-->
          <!--            <div class="left">-->
          <!--              <div class="name">隐藏简历</div>-->
          <!--              <div class="desc">已找到工作, 简历企业不可见</div>-->
          <!--            </div>-->
          <!--            <el-switch-->
          <!--                style="display: block"-->
          <!--                v-model="value4"-->
          <!--                active-color="#13ce66"-->
          <!--                inactive-color="#C9CDD4">-->
          <!--            </el-switch>-->
          <!--          </div>-->
          <div class="item" style="flex-direction: column;">
            <div style="display: flex;align-items: center;width: 100%;justify-content: space-between">
              <div class="left">
                <div class="name">屏蔽企业</div>
                <div class="desc">添加屏蔽公司后，你和这些公司都不会被相互推荐</div>
              </div>
              <div class="right" @click="dialogVisible = true">
                <img src="https://static.dasdt.cn/images/userCenter/add.png">
                <span>添加企业</span>
              </div>
            </div>
            <div class="border"></div>
            <div class="qiye">
              <div class="its" v-for="(item, index) in this.$store.getters.userInfo.blacklistComment" :key="index">
                <div class="name">{{ item }}</div>
                <div class="desc" @click="jiechu(index)">解除屏蔽</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer/>
    <floatRight/>
    <el-dialog
        title="添加屏蔽公司"
        :visible.sync="dialogVisible"
        width="566px"
        :before-close="handleClose">
      <div class="pinbi">
        <!--        <el-input-->
        <!--            style="margin-top: 30px"-->
        <!--            placeholder="搜索公司全称、简称"-->
        <!--            prefix-icon="el-icon-search"-->
        <!--            v-model="input1">-->
        <!--        </el-input>-->
        <el-select
            style="margin-top: 30px"
            @change="changeSelect"
            placeholder="搜索公司全称、简称"
            remote
            multiple
            reserve-keyword

            v-model="input1"
            filterable
            :filter-method="filterMethod"
        >
          <el-option v-for="item in companyList" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
        <div class="tips" v-if="!isShow">
          <div class="title">可通过以下方式搜索公司</div>
          <li class="item">公司全称：如“北京华品博睿网络技术有限公司</li>
          <li class="item">公司简称：如“云梯</li>
        </div>
        <div class="footers" v-else>
          <div class="quanxuan">
            <el-checkbox @change="handleCheckAllChange" v-model="checked">
              <div style="color: #333333">所有与<span style="color: #3A76FF">{{ title }}</span> 相关的公司</div>
            </el-checkbox>
          </div>
          <div class="btn" @click="submit">屏蔽所选公司</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Header from "@/components/headers/index.vue";
import floatRight from "@/components/floatRight/vue.vue";
import Footer from "@/components/footer/index.vue";
import {getCurrentResume, updateMember} from "@/api/currency";
import {companyPage, updateBlacklist} from "@/api/position";

export default {
  components: {Footer, floatRight, Header},
  data() {
    return {
      isShow: false,
      checked: false,
      form: {
        ...this.$store.getters.userInfo
      },
      input1: '',
      value2: true,
      value3: true,
      value4: false,
      dialogVisible: false,
      dv: '',
      companyList: [],
      companyId: [],
      title: ''
    }
  },
  created() {
    console.log(1111111)
    this.getCurrentResume()

    this.value2 = !this.form.isClose
    this.value3 = this.form.isDeliver
  },
  watch: {
    companyId(val) {
      if (val.length > 0) {
        this.isShow = true
      } else {
        this.isShow = false
      }
    }
  },
  methods: {
    async getCurrentResume() {
      const {data} = await getCurrentResume()
      await this.$store.commit('userInfo', data[0])
      this.dv = data[0].dv
    },
    handleClose() {
      this.dialogVisible = false
    },
    async change1(val) {
      if (val === true) {
        this.form.isClose = false
      } else {
        if(this.value3 === true) {
          this.form.isDeliver = false
          this.value3 = false
        }
        this.form.isClose = true
      }
      await this.getCurrentResume()
      const res = await updateMember(this.form, this.dv)
      console.log(res)
      this.$message({
        message: '修改成功',
        type: 'success'
      })
      await this.getCurrentResume()
    },
    async change2(val) {
      if (val === true) {
        if(this.value2 === false) {
          this.form.isClose = false
          this.value2 = true
        }
        this.form.isDeliver = true
      } else {
        this.form.isDeliver = false
      }
      await this.getCurrentResume()

      const res = await updateMember(this.form, this.dv)
      console.log(res)
      this.$message({
        message: '修改成功',
        type: 'success'
      })
      await this.getCurrentResume()
    },
    async filterMethod(val) {
      this.title = val
      let name = val + '%'
      console.log(name)

      const {data} = await companyPage({name: name}, {
        number: 1,
        limit: 10
      })
      this.companyList = data
      console.log(data)
    },
    changeSelect(val) {
      this.companyId = val
      console.log(this.companyId)
    },
    handleCheckAllChange(checked) {
      if (checked) {
        this.input1 = this.companyList.map(option => option.id);
      } else {
        this.input1 = [];
      }
    },
    async submit() {
      if (this.input1.length === 0) {
        this.$message({
          message: '请选择公司',
          type: 'warning'
        })
      } else {
        await this.getCurrentResume()
        const {data} = await updateBlacklist( Array.from(new Set([...this.input1, ...this.form.blacklist || []]))  , this.dv)
        console.log(data)
        await this.getCurrentResume()
        this.dialogVisible = false
        this.$message({
          message: '屏蔽成功',
          type: 'success'
        })
        location.reload();
      }
    },
    async jiechu(index) {
      this.form.blacklist.splice(index, 1)
      const {data} = await updateBlacklist(this.form.blacklist || [], this.dv)
      console.log(data)
      await this.getCurrentResume()
      // await this.getCurrentResume()
      // const res = await updateMember(this.form, this.dv)
      // console.log(res)
      // if(res.code === 'A00000') {
      //   console.log(1111111111)
      //   await this.getCurrentResume()
      // }
      this.$message({
        message: '解除成功',
        type: 'success'
      })
    }
  }
}
</script>
<style lang="scss">
.Privacy {
  background: #F5F5F6;
  width: 100%;
  overflow: hidden;

  .el-select {
    width: 100%;
  }

  //min-height: calc(100vh);
  .pinbi {
    height: 425px;
    position: relative;

    .footers {
      width: 100%;
      position: absolute;
      bottom: 23px;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn {
        background: #3A76FF;
        width: 170px;
        height: 44px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
      }
    }

    .tips {
      margin-top: 30px;

      .title {
        margin-bottom: 13px;
        color: #555555;
        font-size: 14px;
      }

      .item {
        color: #808080;
        font-size: 14px;
        line-height: 24px;
      }
    }

    .el-input__inner {
      border-radius: 100px;
    }
  }

  .contents {
    margin: 72px auto 24px auto;
    width: 1000px;
    min-height: calc(100vh - 160px);
    background: #fff;
    border-radius: 8px;
    position: relative;
    z-index: 10;
    .contentWrap {

      padding: 22px;

      .list {
        margin-top: 20px;
        display: flex;
        flex-direction: column;
        gap: 20px;

        .border {
          width: 100%;
          margin-top: 14px;
          margin-bottom: 22px;
          border-bottom: 1px solid #E6E6E6;
        }

        .qiye {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;

          .its {
            display: flex;
            align-items: center;
            justify-content: space-between;

            .name {
              color: #333333;
              font-size: 14px;
            }

            .desc {
              cursor: pointer;
              color: #808080;
              font-size: 14px;

              &:hover {
                color: #3A76FF;
              }
            }
          }
        }

        .item {
          border-radius: 12px;
          background: #F8F8F8;
          padding: 14px 16px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            display: flex;
            align-items: center;
            gap: 4px;
            cursor: pointer;

            img {
              width: 14px;
              height: 14px;
            }

            span {
              color: #67686F;
              font-size: 14px;
              font-weight: 400;
            }
          }

          .left {
            display: flex;
            flex-direction: column;
            gap: 10px;

            .name {
              color: #333333;
              font-size: 16px;
              font-weight: 600;
            }

            .desc {
              color: #808080;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }

      }

      .title {
        color: #000000;
        font-size: 24px;
        font-weight: 500;
      }
    }
  }
}
</style>
